<template>
  <!-- 抵扣券基本信息 -->
  <div class="box100">
    <el-form class="settingInfoBox" :model="reInfo" :rules="reInfoRule" ref="reInfoForm">
      <div class="basicClass">
        <p class="formTitle">基本信息</p>
        <div class="flexCB">
          <el-form-item label="抵扣券名称" class="detailFormItem" prop="mdname">
            <el-input v-model="reInfo.mdname"  placeholder="请输入抵扣券名称" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="抵扣券类型" class="detailFormItem fiLabelLeft" prop="mdtype">
            <el-radio :label="1" v-model="reInfo.mdtype">全额抵扣</el-radio>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="面值" class="detailFormItem" prop="mdprice">
            <el-input v-model="reInfo.mdprice"  placeholder="请输入面值金额" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="发券数量" class="detailFormItem" prop="mdcount">
            <el-input v-model="reInfo.mdcount"  placeholder="请输入发券数量" maxlength="20"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="发放方式" class="detailFormItem" prop="mdpayway">
            <el-select v-model="reInfo.mdpayway" placeholder="请选择发放方式" style="width: 100%;">
              <el-option v-for="item in mkstateList" :key="item.index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适用人群" class="detailFormItem fiLabelLeft" prop="mddapt">
            <el-radio-group v-model="reInfo.mddapt">
              <el-radio :label="1">全部用户</el-radio>
              <el-radio :label="2">新用户</el-radio>
              <el-radio :label="3">老用户</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="内容描述" class="detailFormItem" prop="mdmark">
            <el-input v-model="reInfo.mdmark" :rows="4" type="textarea" class="resizeNone" maxlength="200" placeholder="请输入内容描述(最多200字)"></el-input>
          </el-form-item>
          <el-form-item label="使用须知" class="detailFormItem" prop="mdinstruc">
            <el-input v-model="reInfo.mdinstruc" :rows="4" type="textarea" class="resizeNone" maxlength="200" placeholder="请输入使用须知(最多200字)"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="有效期" prop="mdeffe" class="fiLabelLeft">
          <p><el-radio v-model="reInfo.mdeffe" :label="1">永久</el-radio></p>
          <div class="flexAC">
            <el-radio v-model="reInfo.mdeffe" :label="2">固定时段</el-radio>
            <el-date-picker
              v-model="reInfo.time"
              type="daterange"
              style="width: 40%;"
              :disabled="reInfo.mdeffe != 2"
              value-format="yyyy-MM-dd"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="flexAC formClass" style="margin-top:20px;">
            <el-radio v-model="reInfo.mdeffe" :label="3">自领取起</el-radio>
            <el-input v-model="reInfo.mddays" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入时间" :disabled="reInfo.mdeffe != 3" style="width: 150px;"></el-input>天
          </div>
        </el-form-item>
      </div>
    </el-form>
    <!--  提交按钮  -->
    <div class="flexCE" style="position: absolute;bottom: 0px;right: 0px;">
      <div class="settingCancel" @click="infoCancel">取消</div>
      <div class="settingConfirm" @click="infoConfirm">保存</div>
    </div>
  </div>
</template>

<script>
import { markdeAdd, markdeGetId, markdeEdit } from '@/api/market'

export default {
  props: ['rebateInfoEdit'],
  data () {
    const moneyReg = (rule, value, callback) => {
      if (value.length > 10) {
        callback(new Error('长度在 0 到 10 个字符'))
      } else {
        setTimeout(() => {
          if (!/^\d+[.]?\d{0,2}$/.test(value) && value) {
            callback(new Error('请输入正整数或小数保留两位小数'))
          } else {
            callback()
          }
        }, 500)
      }
    }
    const numReg = (rule, value, callback) => {
      if (value.length > 10) {
        callback(new Error('长度在 0 到 10 个字符'))
      } else {
        setTimeout(() => {
          if (!/^[1-9]+[0-9]*$/.test(value) && value) {
            callback(new Error('请输入正整数'))
          } else {
            callback()
          }
        }, 500)
      }
    }
    return {
      reInfo: {
        mdname: '',
        mdtype: 1,
        mdprice: '',
        mdcount: '',
        mdpayway: '',
        mddapt: 1,
        mdmark: '',
        mdinstruc: '',
        mdeffe: 1,
        mdstime: '',
        mdetime: '',
        mddays: '',
        time: []
      },
      reInfoRule: {
        mdname: [
          { required: true, trigger: 'blur', message: '抵扣券名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        mdtype: { required: true, trigger: 'blur', message: '抵扣券类型不能为空' },
        mdprice: [
          { required: true, trigger: 'blur', message: '面值不能为空' },
          { trigger: 'blur', validator: moneyReg }
        ],
        mdcount: [
          { required: true, trigger: 'blur', message: '发券数量不能为空' },
          { validator: numReg, trigger: 'blur' }
        ],
        mdpayway: { required: true, trigger: 'blur', message: '请选择发放方式' },
        mddapt: { required: true, trigger: 'blur', message: '适用人群' },
        mdmark: { required: true, trigger: 'blur', message: '内容描述不能为空' },
        mdinstruc: { required: true, trigger: 'blur', message: '使用须知不能为空' },
        mdeffe: { required: true, trigger: 'blur', message: '请选择有效期' }
      },
      mkstateList: [{ label: '购买赠送', value: 1 }, { label: '领取', value: 2 }]
    }
  },
  created () {
    if (this.rebateInfoEdit !== null) {
      this.getInfo()
    }
  },
  methods: {
    // 抵扣券详情查询
    getInfo () {
      markdeGetId({ mdid: this.rebateInfoEdit.mdid }).then(res => {
        if (res.status === 1) {
          this.reInfo = res.data.data[0]
          if (this.reInfo.mdeffe === 2) { // 固定时段
            this.reInfo.time = [this.reInfo.mdstime, this.reInfo.mdetime]
          }
        }
      })
    },
    infoCancel () {
      this.$emit('HandCancel')
    },
    // 表单提交
    infoConfirm () {
      this.$refs.reInfoForm.validate((valid) => {
        if (!valid) return
        let _st = ''
        let _et = ''
        if (this.reInfo.mdeffe === 2) {
          if (this.reInfo.time.length === 0) {
            this.$message.warning('请选择时间')
            return
          } else {
            _st = this.reInfo.time[0]
            _et = this.reInfo.time[1]
          }
        } else if (this.reInfo.mdeffe === 3) {
          if (this.reInfo.mddays === '') {
            this.$message.warning('请输入时间')
            return
          }
        }
        const params = {
          mdid: this.reInfo.mdid, // id
          mdname: this.reInfo.mdname, // 抵扣券名称
          mdtype: this.reInfo.mdtype, // 抵扣券类型
          mdprice: this.reInfo.mdprice, // 抵扣券面值
          mdcount: this.reInfo.mdcount, // 发券数量
          mdpayway: this.reInfo.mdpayway, // 发放方式（1：购买赠送，2：领取）
          mddapt: this.reInfo.mddapt, // 适用人群（1：全部用户  2：新用户  3：老用户）
          mdmark: this.reInfo.mdmark, // 类容描述
          mdinstruc: this.reInfo.mdinstruc, // 使用须知
          mdeffe: this.reInfo.mdeffe, // 有效期（1：永久  2：固定时段  3：购买天数）
          mdstime: _st, // 固定时段开始时间
          mdetime: _et, // 固定时段结束时间
          mddays: this.reInfo.mddays // 购买天数
        }
        if (this.rebateInfoEdit === null) { // 新增提交
          markdeAdd(params).then(res => {
            if (res.status === 1) {
              this.$alert('新增抵扣券基本信息成功，请尽快完善“适用门店” “匹配服务”信息', '保存成功', {
                confirmButtonText: '确定',
                callback: action => {
                  this.$emit('formSuccess')
                }
              })
            } else {
              this.$message.error(res.message)
            }
          })
        } else { // 编辑提交
          markdeEdit(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$emit('formSuccess')
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
