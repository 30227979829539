<template>
  <!-- 抵扣券 -->
  <div class="box100">
    <div class="whiteBg" v-if="!showForm">
      <template v-if="roleBtn[0].power">
        <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
      </template>
      <div class="flexAC" style="margin: 16px 0px;">
        <el-input v-model="mdname"  placeholder="请输入抵扣券名称" class="inputSearch"></el-input>
        <el-select v-model="mdstate" placeholder="状态" class="retSelect">
          <el-option v-for="item in mdstateList" :key="item.index" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
        <el-button class="btnReset" @click="handleReset">重置</el-button>
      </div>
      <div class="table_list">
        <el-table
          :data="rebtList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="抵扣券名称"
            prop="mdname">
          </el-table-column>
          <el-table-column
            label="发放方式"
            prop="mdpayway">
            <template slot-scope="scope">
              <span v-if="scope.row.mdpayway === 1">购买赠送</span>
              <span v-if="scope.row.mdpayway === 2">领取</span>
            </template>
          </el-table-column>
          <el-table-column
            label="优惠内容"
            prop="mdmark"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="mcanum"
            label="已领取/已使用/剩余">
            <template slot-scope="scope">
              <span>{{ scope.row.isoknum }}/{{ scope.row.isnonum }}/{{ scope.row.issnum }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="适用门店"
            prop="shop_count">
          </el-table-column>
          <el-table-column
            label="状态"
            prop="mdstate">
            <template slot-scope="scope">
              <span v-if="scope.row.mdstate === 1" >已上架</span>
              <span v-if="scope.row.mdstate === 2" >已下架</span>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="creatime">
          </el-table-column>
          <el-table-column
          label="操作"
          width="200">
          <template slot-scope="scope">
            <template v-if="roleBtn[2].power">
              <el-button type="text" style="color:#3C84EF" size="small" @click="handEdit(scope.row)">编辑</el-button>
            </template>
            <template v-if="roleBtn[3].power">
              <el-button type="text" style="color:#44B557" size="small" @click="handishelf(scope.row)"  v-if="scope.row.mdstate === 2">上架</el-button>
              <el-button type="text" style="color:#F44C8E" size="small" @click="handishelf(scope.row)"  v-if="scope.row.mdstate === 1">下架</el-button>
            </template>
            <template v-if="roleBtn[1].power">
              <el-button type="text" size="small"  style="color:#F44C8E;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
        </el-table>

        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <div class="box100" v-if="showForm">
      <RebateForm :rebateInfoEdit="rebateInfoEdit" @formCancel="formCancel" @formSuccess="formSuccess"></RebateForm>
    </div>
  </div>
</template>

<script>
import RebateForm from '@/components/marketing/rebateForm'
import { markdeGet, markdeDelete, markdeState } from '@/api/market.js'
export default {
  components: {
    RebateForm
  },
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      rebtList: [],
      mdname: '',
      mdstate: '',
      mdstateList: [{ label: '上架', value: 1 }, { label: '下架', value: 2 }],
      showForm: false,
      rebateInfoEdit: {}
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        mdname: this.mdname,
        mdstate: this.mdstate
      }
      markdeGet(params).then(res => {
        if (res.status === 1) {
          this.rebtList = res.data.data
          this.total = res.data.recordcount
        }
      })
    },
    // 点击新增
    handleAdd () {
      this.showForm = true
      this.rebateInfoEdit = null
    },
    // 编辑
    handEdit (row) {
      this.rebateInfoEdit = { ...row }
      this.showForm = true
    },
    // 取消表单提交
    formCancel () {
      this.showForm = false
    },
    // 表单提交成功
    formSuccess () {
      this.showForm = false
      this.init()
    },
    // 上架  下架
    handishelf (row) {
      let tip = ''
      if (row.mdstate === 2) { tip = '上架' }
      if (row.mdstate === 1) { tip = '下架' }
      this.$confirm('确定执行' + tip + '操作, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markdeState({ mdid: row.mdid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该服务, 是否继续?', '删除服务', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markdeDelete({ mdid: row.mdid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
