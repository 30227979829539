<template>
  <div class="specialForm">
    <div class="specialFL">
      <div class="sflName" :class="serveFormType === '0' ? 'sfActive': ''" @click="changeForm('0')">基本信息</div>
      <div class="sflName"  v-if="rebateInfoEdit !== null" :class="serveFormType === '1' ? 'sfActive': ''" @click="changeForm('1')">适用门店</div>
      <div class="sflName"  v-if="rebateInfoEdit !== null" :class="serveFormType === '2' ? 'sfActive': ''" @click="changeForm('2')">匹配服务</div>
    </div>
    <div class="specialFR">
      <!-- 基本信息 -->
      <div class="formItemType" v-if="serveFormType === '0'">
        <RebaInfo :rebateInfoEdit="rebateInfoEdit" @HandCancel="HandCancel" @formSuccess="formSuccess"></RebaInfo>
      </div>

      <!-- 适用门店 -->
      <div class="formItemType" v-if="serveFormType === '1'">
        <div class="table_list">
          <el-table
            ref="shopTable"
            :data="shopList"
            :header-cell-style="{background:'#EBF2FE'}"
            :row-key="getRowKeys"
            @row-click="handleRowClick"
            @selection-change="selectionChange">
            <el-table-column
              prop="shopid"
              type="selection">
            </el-table-column>
            <el-table-column
              label="门店编号"
              type="index"
              width="100">
            </el-table-column>
            <el-table-column
              label="区域"
              prop="areas_name">
            </el-table-column>
            <el-table-column
              label="门店名称"
              prop="shopname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="负责人"
              prop="shopuser">
            </el-table-column>
            <el-table-column
              label="联系电话"
              prop="superphone">
            </el-table-column>
            <el-table-column
              label="门店类型"
              prop="shoptype">
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>

        <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
          <div class="settingCancel" @click="HandCancel">取消</div>
          <div class="settingConfirm" @click="handSetConfirm">确定</div>
        </div>
      </div>

      <!-- 匹配服务 -->
      <div class="formItemType" v-if="serveFormType === '2'">
        <!-- 已配置服务  -->
        <div v-if="!showList">
          <div class="settingAdd serveadd" @click="handAddServe"><span>添加服务</span></div>
          <div class="table_list" style="margin-top: 16px;">
            <el-table
              :data="selectList"
              :header-cell-style="{background:'#EBF2FE'}">
              <el-table-column
                label="序号"
                type="index">
              </el-table-column>
              <el-table-column
                label="服务名称"
                prop="servename">
              </el-table-column>
              <el-table-column
                label="服务分组"
                prop="sgname_one">
                <template slot-scope="scope">
                  <span>{{scope.row.sgname_one}}/{{scope.row.sgname_two}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="ishelf"
                label="上架状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.ishelf === true" >上架</span>
                  <span v-if="scope.row.ishelf === false">下架</span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作">
                <template slot-scope="scope">
                  <el-button type="text" size="small" style="color:#F44C8E;" @click="handServeDelete(scope.row)">移除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
            <div class="settingCancel" @click="infoCancel">返回</div>
          </div>
        </div>
        <!-- 选择服务列表  -->
        <div v-if="showList">
          <div class="table_list">
            <el-table ref="serveTable"
              :data="chooseList"
              :header-cell-style="{background:'#EBF2FE'}"
              :row-key="getServeKeys"
              @select="serveSelect"
              @selection-change="serveChange">
              <el-table-column
                prop="serveid"
                type="selection">
              </el-table-column>
              <el-table-column
                label="序号"
                type="index">
              </el-table-column>
              <el-table-column
                label="服务名称"
                prop="servename">
              </el-table-column>
              <el-table-column
                label="服务分组"
                prop="sgname_one">
                <template slot-scope="scope">
                  <span>{{ scope.row.sgname_one }}/{{ scope.row.sgname_two }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="ishelf"
                label="上架状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.ishelf === true" >上架</span>
                  <span v-if="scope.row.ishelf === false">下架</span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="servePageChange"
              :current-page="servePage"
              :page-sizes="[10]"
              :page-size="serveSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="serveTotal"
              class="settingPage">
            </el-pagination>
          </div>
          <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
            <div class="settingCancel" @click="serveCancel">返回</div>
            <div class="settingConfirm" @click="serveConfirm">确定</div>
          </div>
        </div>
      </div>
      <!-- 匹配服务-结束 -->
    </div>
  </div>
</template>

<script>
import RebaInfo from '@/components/marketing/rebaInfo'
import { markdeShop, markdeShopID, markdeServeList, markdeServeQuery, markdeServeAdd, markdeServeDel } from '@/api/market.js'
export default {
  props: ['rebateInfoEdit'],
  components: {
    RebaInfo
  },
  data () {
    return {
      serveFormType: '0',
      page: 1,
      size: 10,
      total: 0,
      shopList: [],
      multipleSelection: [],
      showList: false,
      selectList: [], // 已绑定列表
      servePage: 1, // 绑定服务分页
      serveSize: 10,
      serveTotal: 0,
      chooseList: [], // 服务列表
      serveSelection: []
    }
  },
  created () {

  },
  methods: {
    changeForm (type) {
      this.serveFormType = type
      if (type === '1') { // 适用门店
        if (this.rebateInfoEdit !== null) {
          this.init()
        }
      } else if (type === '2') { // 匹配服务
        if (this.rebateInfoEdit !== null) {
          this.getInit()
        }
      }
    },
    // 适用门店 查询
    init () {
      markdeShop({ mdid: this.rebateInfoEdit.mdid }).then(res => {
        if (res.status === 1) {
          this.shopList = res.data.data
          this.total = res.data.recordcount
          this.$nextTick(() => {
            for (let i = 0; i < this.shopList.length; i++) {
              if (this.shopList[i].ischoose === 1) {
                this.$refs.shopTable.toggleRowSelection(this.shopList[i])
              }
            }
          })
        }
      })
    },
    getRowKeys (row) {
      return row.shopid
    },
    handleRowClick (row, column, event) {
      this.$refs.shopTable.toggleRowSelection(row)
    },
    selectionChange (val) {
      if (val.length === 0) {
        this.multipleSelection = []
      } else {
        this.multipleSelection = val
      }
    },
    // 适用门店 确定
    handSetConfirm () {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先选择门店')
      } else {
        const arr = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          arr.push(this.multipleSelection[i].shopid)
        }
        this.$confirm('确认是否绑定该门店', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          markdeShopID({ shopid: arr, mdid: this.rebateInfoEdit.mdid }).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      }
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 基本信息取消
    HandCancel () {
      this.$emit('formCancel')
    },
    // 基本信息确定
    formSuccess () {
      this.$emit('formSuccess')
    },
    // 配置服务【已选择列表】
    getInit () {
      markdeServeList({ mdid: this.rebateInfoEdit.mdid }).then(res => {
        if (res.status === 1) {
          this.selectList = res.data.data
        }
      })
    },
    // 配置服务【点击添加服务】
    handAddServe () {
      this.showList = true
      this.serveSelection = []
      this.chooseQuery()
    },
    // 配置服务【移除服务】
    handServeDelete (row) {
      this.$confirm('当前适用服务是否移除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markdeServeDel({ mdasid: row.mdasid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.getInit()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 配置服务【服务列表】
    chooseQuery () {
      markdeServeQuery({ page_index: this.servePage, page_size: this.serveSize, mdid: this.rebateInfoEdit.mdid }).then(res => {
        if (res.status === 1) {
          this.chooseList = res.data.data
          this.serveTotal = res.data.recordcount
        }
      })
    },
    getServeKeys (row) {
      return row.serveid
    },
    serveSelect (selection, row) {
      if (selection.length > 1) {
        const _row = selection.shift()
        this.$refs.serveTable.toggleRowSelection(_row, false)
      }
    },
    serveChange (val) {
      const arr = [val[0].serveid]
      this.serveSelection = arr
    },
    // 配置服务【服务翻页】
    servePageChange (page) {
      this.servePage = page
      this.chooseQuery()
    },
    // 配置服务【添加服务】[返回]
    serveCancel () {
      this.showList = false
    },
    // 配置服务【添加服务】[确定]
    serveConfirm () {
      if (this.serveSelection.length === 0) {
        this.$message.warning('请选择服务')
      } else {
        this.$confirm('请确认您是否要对以上所选的服务进行添加', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          markdeServeAdd({ mdid: this.rebateInfoEdit.mdid, serveid: this.serveSelection }).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.showList = false
              this.getInit()
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      }
    },
    infoCancel () {
      this.$emit('formCancel')
    }
  }
}
</script>

<style scoped>

</style>
